import './style.css'
import { useTranslation } from "react-i18next";

function Footer() {
    const { t } = useTranslation();

    
	const openNewPage = (url) => {
		window.open(url);
	}

    return (
        <div className="footer">
            <div className="split"></div>
            <div className="socialWrap">
                <span className="feebackFooter"> {t("feeback_footer")}</span>
                <span className="socialMediaAccount"> {t("social_media_account_footer")}</span>
            </div>

            <div className="copyright"> {t("copyright_footer")}</div>

            <div className="icpWrap">
                <span onClick={() => { openNewPage("https://beian.miit.gov.cn/") }} className="icpFooter"> {t("icp_footer")}</span>
                <span className="pnsIcon"> </span>
                <span onClick={() => { openNewPage("https://beian.mps.gov.cn/#/query/webSearch?code=41019702003548") }} className="psnRecord">{t("psn_record_footer")}</span>
            </div>
        </div>
    );
}

export default Footer;
