import { useEffect, useState } from 'react';
import './App.css';
import Header from './components/header/index';
import Content from './components/content/index';
import Footer from './components/footer/index';
import i18n from "i18next";

function App() {

  const [curLang, setCurLang] = useState('');
  
  useEffect(() => {
    // 获取本地存储预览、浏览器默认选中语言
    const lang = localStorage.getItem('local') || (['en', 'en-US', 'en-us'].includes(navigator.language) ? 'en' : 'zh');
    setCurLang(lang);
}, [])

 // 切换中英语言
 const changLang = () => {
  const nextLange=curLang === 'en' ? 'zh' : 'en';
  i18n.changeLanguage(nextLange);
  localStorage.setItem('local', nextLange);
  setCurLang(nextLange);
}

  return (
    <div className="App">
      <Header changLang={changLang} curLang={curLang} ></Header> 
      <Content curLang={curLang}></Content>
      <Footer></Footer>
    </div>
  );
}

export default App;
