import './style.css'
import { useTranslation } from "react-i18next";

function Content(props) {

	const { t } = useTranslation();

	// 下载App
	const goDownloadApp = (url) => {
		window.open(url);
	}

	return (
		<div className="todoContent">

			<div className="introHeading">{t("intro_heading")}</div>

			<div className="introNotes">{t("intro_notes")}</div>

			<div className="downloadAppGroupWrap">
				{/* <div className="downloadAppWrap"> */}
				<a href="https://apps.apple.com/app/id6503290073" className="downloadAppWrap">
					<span className="iosLogo"></span>
					<span className="downloadInfo">{t("download_ios")}</span>
				</a>
				{/* <div onClick={() => { goDownloadApp("https://www.springlove.cc/android/app/apk?timestamp=" + Date.now()) }} className="downloadAppWrap"> */}
				<a href="https://www.springlove.cc/android/apk" className="downloadAppWrap">
				{/* <a href="https://sj.qq.com/appdetail/com.springlove.springlove" className="downloadAppWrap"> */}
					<span className="androidLogo"></span>
					<span className="downloadInfo">{t("download_android")}</span>
				</a>
			</div>

			<div className="toToAppImgWrap">
				<div className={`toToAppImgHome ${"toToAppImgHomeZh"}`} ></div>
				<div className={`toToAppImgPast ${"toToAppImgPastZh"}`}></div>
			</div>
		</div>
	);
}

export default Content;
