import { useState } from 'react';
import './style.css'
import { useTranslation } from "react-i18next";

function Header(props) {

    const [isHoverStyle, setIsHoverStyle] = useState(false);

    const handleTouchstart = () => {
       setIsHoverStyle(true);
    }
    const handleTouchend = () => {
        setTimeout(()=>{setIsHoverStyle(false)});
     }

    const { t } = useTranslation();
    return (
        <div className="header">
            <div className="headerBar">
                <div className={`appName`}>
                    <span className="appNameLogo"></span>
                    {t("app_name")}
                </div>
            </div>
        </div>
    );
}

export default Header;
